import React from 'react'
import { makeStyles, Menu, MenuItem } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '150px !important',
    backgroundColor: '#000',
  },
  link: {
    justifyContent: 'space-between',
  },
  linkIcon: {
    marginLeft: theme.spacing(2),
  },
}))

const MobileMenu = props => {
  const { anchor, onClose, items, onClick } = props

  const classes = useStyles()

  return (
    <Menu
      classes={{ paper: classes.root }}
      anchorEl={anchor}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchor)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {items &&
        items.map((item, index) => (
          <MenuItem key={index} dense onClick={() => onClick(item.url)}>
            {item.title}
          </MenuItem>
        ))}
    </Menu>
  )
}

export default MobileMenu
