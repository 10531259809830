import React from 'react'
import { makeStyles, Typography, IconButton } from '@material-ui/core'
import UpButton from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexShrink: 0,
    height: '140px',
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 2,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '140px',
    width: '100%',
  },
  upButton: {
    marginTop: theme.spacing(-2),
  },
}))

const Footer = props => {
  const classes = useStyles(props)

  const gotoAnchor = id => {
    console.log('%c Scrolling to', 'color:lightblue', id)
    document
      .getElementById(id)
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <IconButton
          className={classes.upButton}
          onClick={() => gotoAnchor('#top')}
        >
          <UpButton fontSize="large" />
        </IconButton>
        <Typography color="textSecondary">
          Copyright {new Date().getFullYear()}{' '}
          <Typography component="span" color="primary">
            webcrew
          </Typography>
        </Typography>
      </div>
    </div>
  )
}

export default Footer
