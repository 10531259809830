import React from 'react'
import { Route, Switch } from 'react-router-dom'

import HomePage from './components/pages/home/HomePage'

const Routes = props => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />

      <Route path="*" component={HomePage} />
    </Switch>
  )
}

export default Routes
