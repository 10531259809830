import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  makeStyles,
  Container,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import ServiceIcon from '@material-ui/icons/EmojiPeople'
import SpeedIcon from '@material-ui/icons/Speed'
import SecurityIcon from '@material-ui/icons/Security'
import DownIcon from '@material-ui/icons/ExpandMore'
import InputMask from 'react-input-mask'
import tagSphere from './tagSphere'
import siteData from './siteData'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/analytics'
import { useLocation } from 'react-router'

if (!window.initiated) {
  firebase.initializeApp({
    apiKey: 'AIzaSyD5GsFAI-nGqHS8a7RUOHq3gL56mrj8peg',
    authDomain: 'dev-site-d2785.firebaseapp.com',
    projectId: 'dev-site-d2785',
    storageBucket: 'dev-site-d2785.appspot.com',
    messagingSenderId: '168373388346',
    appId: '1:168373388346:web:1dc060197d0f876f4fa103',
    measurementId: 'G-GL57JEQKKF',
  })
  if (process.env.NODE_ENV !== 'development') firebase.analytics()
  if (process.env.NODE_ENV === 'development') {
    firebase.functions().useEmulator('localhost', 5001)
  }
  window.initiated = true
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '-88px',
    maxWidth: 'unset',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    maxWidth: '1000px',
  },

  pane: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4, 8),
    borderRadius: theme.spacing(1),
    backgroundColor: 'rgba(0,0,0,0.6)',

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2),
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    marginBottom: theme.spacing(3),
  },
  text: {
    fontSize: '1.2rem',
    width: '100%',
  },
  textCenter: {
    fontSize: '1.2rem',
    width: '100%',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '1.7rem',
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  images1: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  images2: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '120px',
    height: 'auto',
  },

  downButton: {
    marginTop: theme.spacing(3),
  },

  form: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    maxWidth: '400px',
    marginTop: theme.spacing(3),
  },
  input2: {
    width: '100%',
    maxWidth: '400px',
    marginTop: theme.spacing(3),
    display: 'none',
  },

  button: {
    marginTop: theme.spacing(4),
  },

  word: {
    wordBreak: 'none',
  },

  fadeIn: {
    opacity: 0,
    transform: 'translateY(100px)',
  },
  fadeInAnimate: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'opacity 1s ease, transform 0.5s ease',
  },
  fadeInDelay1: {
    transitionDelay: '0.25s',
  },
  fadeInDelay2: {
    transitionDelay: '0.5s',
  },
}))

const HomePage = props => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [emailConfirm, setEmailConfirm] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [submitMessage, setSubmitMessage] = useState(null)

  const [animateHeader, setAnimateHeader] = useState(false)
  const [animateMission, setAnimateMission] = useState(false)
  const [animateServices, setAnimateServices] = useState(false)
  const [animateTechnology, setAnimateTechnology] = useState(false)
  const [animateContact, setAnimateContact] = useState(false)

  const [missionRef, missionInView] = useInView()
  const [servicesRef, servicesInView] = useInView()
  const [technologyRef, technologyInView] = useInView()
  const [contactRef, contactInView] = useInView()

  const classes = useStyles()
  const location = useLocation()

  useEffect(() => {
    console.log(
      '%c Welcome to webcrew.io!',
      'color:lightblue; font-size: 16px;',
    )

    setAnimateHeader(true)

    tagSphere.load('word-sphere')
    console.log('%c Loading word-sphere...', 'color:lightblue')

    if (location.hash) setTimeout(() => gotoAnchor(location.hash), 1000)

    return () => tagSphere.destroy()
  }, [])

  useEffect(() => {
    if (missionInView && !animateMission) {
      console.log('%c Mission observer true', 'color:lightblue')
      setAnimateMission(true)
    }
  }, [missionInView])

  useEffect(() => {
    if (servicesInView && !animateServices) {
      console.log('%c Services observer true', 'color:lightblue')
      setAnimateServices(true)
    }
  }, [servicesInView])

  useEffect(() => {
    if (technologyInView && !animateTechnology) {
      console.log('%c Technology observer true', 'color:lightblue')
      setAnimateTechnology(true)
    }
  }, [technologyInView])

  useEffect(() => {
    if (contactInView && !animateContact) {
      console.log('%c Contact observer true', 'color:lightblue')
      setAnimateContact(true)
    }
  }, [contactInView])

  const gotoAnchor = id => {
    console.log('%c Scrolling to', 'color:lightblue', id)
    document
      .getElementById(id)
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const doSubmit = async () => {
    if (!name) {
      setSubmitMessage({
        type: 'error',
        message: 'Please add your name.',
      })
      return
    }
    if (!email) {
      setSubmitMessage({
        type: 'error',
        message: 'Please add your email.',
      })
      return
    }
    if (!message) {
      setSubmitMessage({
        type: 'error',
        message: 'Please include a message.',
      })
      return
    }

    console.log('%c Submitting contact form...', 'color:lightblue')
    setSubmitting(true)
    try {
      const req = firebase.functions().httpsCallable('contactUs')
      const response = await req({ name, email, phone, message, emailConfirm })
      console.log('%c Contact form result:', 'color:lightgreen', response.data)
      if (response.data) {
        setSubmitMessage({
          type: 'success',
          message: "Email sent! We'll be in contact soon.",
        })
      } else {
        setSubmitMessage({
          type: 'error',
          message: 'Contact submission failed! Please try again.',
        })
      }
    } catch (error) {
      console.log('%c Contact form error:', 'color:lightred', error)
      setSubmitMessage({
        type: 'error',
        message: 'Contact submission error! Please try again.',
      })
    }
    setSubmitting(false)
  }

  return (
    <Container className={classes.root} disableGutters>
      <div className={classes.topContainer} id="#top">
        <Typography
          variant="h3"
          color="primary"
          className={`${classes.fadeIn} ${
            animateHeader && classes.fadeInAnimate
          }`}
        >
          webcrew
        </Typography>
        <Typography
          variant="h6"
          className={`${classes.fadeIn} ${
            animateHeader && `${classes.fadeInAnimate} ${classes.fadeInDelay1}`
          }`}
        >
          Your custom web development team.
        </Typography>
        <IconButton
          onClick={() => gotoAnchor('#mission')}
          className={`${classes.fadeIn} ${
            animateHeader && `${classes.fadeInAnimate} ${classes.fadeInDelay2}`
          }`}
        >
          <DownIcon fontSize="large" />
        </IconButton>
      </div>

      <Container id="#mission" className={classes.container}>
        <div
          className={`${classes.pane} ${classes.fadeIn} ${
            animateMission && classes.fadeInAnimate
          }`}
          ref={missionRef}
        >
          <Typography
            variant="h4"
            className={`${classes.title} ${classes.fadeIn} ${
              animateMission &&
              `${classes.fadeInAnimate} ${classes.fadeInDelay1}`
            }`}
          >
            Who we are
          </Typography>
          <div
            className={`${classes.content} ${classes.fadeIn} ${
              animateMission &&
              `${classes.fadeInAnimate} ${classes.fadeInDelay2}`
            }`}
          >
            <Typography variant="body1" className={classes.text}>
              <Typography
                component="span"
                color="primary"
                className={classes.text}
              >
                webcrew
              </Typography>{' '}
              is a team of senior-level web developers from the DC area with
              wide-ranging experience including startups, small business,
              enterprise, government, and international organizations - Our team
              has done it all!
            </Typography>
            <Typography
              variant="body1"
              className={classes.text}
              style={{ marginTop: '24px' }}
            >
              Our mission is to provide top-quality web development consulting
              to local businesses, big and small, with a focus on{' '}
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                service
              </Typography>
              ,{' '}
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                speed
              </Typography>
              , and{' '}
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                security
              </Typography>
              .
            </Typography>
            <Grid container className={classes.images1} spacing={3}>
              {siteData.images1.map((item, index) => (
                <Grid key={index} item>
                  <img
                    src={item.url}
                    alt={item.title}
                    className={classes.image}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container className={classes.images2} spacing={3}>
              {siteData.images2.map((item, index) => (
                <Grid key={index} item>
                  <img
                    src={item.url}
                    alt={item.title}
                    className={classes.image}
                  />
                </Grid>
              ))}
            </Grid>
            <Typography variant="caption" color="textSecondary">
              (Some of the biggest names we&apos;ve worked for)
            </Typography>

            <Typography
              variant="body1"
              className={classes.subtitle}
              style={{ marginTop: '24px' }}
            >
              Did you know...?
            </Typography>
            <Typography variant="body1" className={classes.text}>
              All of our team members are CompTIA Security+ certified, Subject
              Matter Experts in software engineering, and residents of the
              Washington, DC National Capital Region.
            </Typography>
            <Typography
              variant="body1"
              className={classes.textCenter}
              style={{ marginTop: '24px' }}
            >
              We call{' '}
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                Bethesda
              </Typography>
              ,{' '}
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                Rockville
              </Typography>
              ,{' '}
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                NoVa
              </Typography>
              , and{' '}
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                DC
              </Typography>{' '}
              home!
            </Typography>

            <IconButton
              className={classes.downButton}
              onClick={() => gotoAnchor('#services')}
            >
              <DownIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </Container>

      <Container id="#services" className={classes.container}>
        <div
          className={`${classes.pane} ${classes.fadeIn} ${
            animateServices && classes.fadeInAnimate
          }`}
          ref={servicesRef}
        >
          <Typography
            variant="h4"
            className={`${classes.title} ${classes.fadeIn} ${
              animateServices &&
              `${classes.fadeInAnimate} ${classes.fadeInDelay1}`
            }`}
          >
            What we do
          </Typography>
          <div
            className={`${classes.content} ${classes.fadeIn} ${
              animateServices &&
              `${classes.fadeInAnimate} ${classes.fadeInDelay2}`
            }`}
          >
            <Typography variant="body1" className={classes.subtitle}>
              <ServiceIcon fontSize="large" style={{ marginRight: '8px' }} />{' '}
              Service
            </Typography>
            <Typography variant="body1" className={classes.text}>
              At{' '}
              <Typography
                component="span"
                color="primary"
                className={classes.text}
              >
                webcrew
              </Typography>
              , we measure our response time in minutes, not days. If you have a
              question, concern, feature request, or just want to check in, you
              can feel confident knowing your email is going directly to the
              developers working on your project.
            </Typography>
            <Typography
              variant="body1"
              className={classes.textCenter}
              style={{ marginTop: '24px' }}
            >
              We are your company’s dev team{' '}
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                (at a fraction of the cost)
              </Typography>
            </Typography>

            <Typography
              variant="body1"
              className={classes.subtitle}
              style={{ marginTop: '32px' }}
            >
              <SpeedIcon fontSize="large" style={{ marginRight: '8px' }} />{' '}
              Speed
            </Typography>
            <Typography variant="body1" className={classes.text}>
              When you hire{' '}
              <Typography
                component="span"
                color="primary"
                className={classes.text}
              >
                webcrew
              </Typography>
              , you&apos;re bringing on a well-oiled development machine to
              tackle your project quickly and efficiently. We take advantage of
              the latest technologies that enable us to deliver maximum value
              for you at minimal cost.
            </Typography>
            <Typography
              variant="body1"
              className={classes.text}
              style={{ marginTop: '24px' }}
            >
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                Need something done yesterday?
              </Typography>{' '}
              We thrive on tight deadlines and can expertly architect elegant
              solutions to your most complex technical requirements.
            </Typography>

            <Typography
              variant="body1"
              className={classes.subtitle}
              style={{ marginTop: '32px' }}
            >
              <SecurityIcon fontSize="large" style={{ marginRight: '8px' }} />{' '}
              Security
            </Typography>
            <Typography variant="body1" className={classes.text}>
              Security is not an afterthought at{' '}
              <Typography
                component="span"
                color="primary"
                className={classes.text}
              >
                webcrew
              </Typography>
              . Our developers are all CompTIA Security+ certified and have
              years of experience developing secure applications for some of the
              world&apos;s biggest organizations.
            </Typography>

            <IconButton
              className={classes.downButton}
              onClick={() => gotoAnchor('#technology')}
            >
              <DownIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </Container>

      <Container id="#technology" className={classes.container}>
        <div
          className={`${classes.pane} ${classes.fadeIn} ${
            animateTechnology && classes.fadeInAnimate
          }`}
          ref={technologyRef}
        >
          <Typography
            variant="h4"
            className={`${classes.title} ${classes.fadeIn} ${
              animateTechnology &&
              `${classes.fadeInAnimate} ${classes.fadeInDelay1}`
            }`}
          >
            Technology
          </Typography>
          <div
            className={`${classes.content} ${classes.fadeIn} ${
              animateTechnology &&
              `${classes.fadeInAnimate} ${classes.fadeInDelay2}`
            }`}
          >
            <Typography variant="body1" className={classes.text}>
              Our technology choices align with our commitment to service,
              speed, and security.
            </Typography>
            <Typography
              variant="body1"
              className={classes.text}
              style={{ marginTop: '24px' }}
            >
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                Service:
              </Typography>{' '}
              Your project will be easy to maintain, utilize well known
              technologies, and be architected so that it can grow as your needs
              change.
            </Typography>
            <Typography
              variant="body1"
              className={classes.text}
              style={{ marginTop: '16px' }}
            >
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                Speed:
              </Typography>{' '}
              We practice agile development and are able to draw from an
              enormous library of past work to customize and build upon.
            </Typography>
            <Typography
              variant="body1"
              className={classes.text}
              style={{ marginTop: '16px' }}
            >
              <Typography
                component="span"
                color="secondary"
                className={classes.text}
              >
                Security:
              </Typography>{' '}
              We implement the latest technologies to secure your project from
              spam, exploitation, and malware.
            </Typography>
            <Typography
              variant="body1"
              className={classes.textCenter}
              style={{ marginTop: '32px' }}
              color="textSecondary"
            >
              A few of our favorite technologies...
            </Typography>

            <div id="word-sphere">
              {siteData.technologies.map((item, index) => (
                <div key={index} className={classes.word}>
                  {item}
                </div>
              ))}
            </div>

            <Typography
              variant="body1"
              className={classes.textCenter}
              color="textSecondary"
            >
              If you don&apos;t see what you&apos;re looking for, just ask!
            </Typography>

            <IconButton
              className={classes.downButton}
              onClick={() => gotoAnchor('#contact')}
            >
              <DownIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </Container>

      <Container id="#contact" className={classes.container}>
        <div
          className={`${classes.pane} ${classes.fadeIn} ${
            animateContact && classes.fadeInAnimate
          }`}
          ref={contactRef}
        >
          <Typography
            variant="h4"
            className={`${classes.title} ${classes.fadeIn} ${
              animateContact &&
              `${classes.fadeInAnimate} ${classes.fadeInDelay1}`
            }`}
          >
            Contact us
          </Typography>
          <div
            className={`${classes.content} ${classes.fadeIn} ${
              animateContact &&
              `${classes.fadeInAnimate} ${classes.fadeInDelay2}`
            }`}
          >
            <Typography variant="body1" className={classes.textCenter}>
              Ready to get started? Contact{' '}
              <Typography
                component="span"
                color="primary"
                className={classes.text}
              >
                webcrew
              </Typography>{' '}
              now!
            </Typography>

            <Typography
              variant="caption"
              style={{ marginTop: '24px' }}
              color="textSecondary"
            >
              * Indicates a required field.
            </Typography>

            <form noValidate autoComplete="off" className={classes.form}>
              <TextField
                required
                className={classes.input}
                id="name-input"
                label="Your Name"
                value={name}
                onChange={e => setName(e.target.value)}
              />

              <TextField
                required
                className={classes.input}
                id="email-input"
                type="email"
                label="Your Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <InputMask
                className={classes.input}
                mask="(999) 999-9999" // Format you need implemented
                value={phone}
                onChange={e => setPhone(e.target.value)}
                label="Your Phone (optional)"
                name="phone"
                maskChar={'_'}
              >
                {inputProps => <TextField {...inputProps} />}
              </InputMask>

              <TextField
                required
                className={classes.input}
                id="message-input"
                label="Your Message"
                value={message}
                onChange={e => setMessage(e.target.value)}
                multiline
                rows={4}
              />

              <TextField
                required
                className={classes.input2}
                id="email2-input"
                label="Confirm Email"
                value={emailConfirm}
                onChange={e => setEmailConfirm(e.target.value)}
              />

              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={() => doSubmit()}
                size="large"
                disabled={submitting}
              >
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>

              {submitMessage && (
                <Alert
                  severity={submitMessage.type}
                  style={{ marginTop: '24px' }}
                  variant="outlined"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setSubmitMessage(null)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {submitMessage.message}
                </Alert>
              )}
            </form>
          </div>
        </div>
      </Container>
    </Container>
  )
}

export default HomePage
