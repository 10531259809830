const radius = 150
const dtr = Math.PI / 180
const d = 300

let mcList = []
const lasta = 0.25
const lastb = 0.25
const distr = true

const howElliptical = 1

let aA = null
let oDiv = null

let sa = 0
let ca = 0
let sb = 0
let cb = 0
let sc = 0
let cc = 0

let per = 0

let interval = null

const colors = [
  '#ff8a80',
  '#ff80ab',
  '#ea80fc',
  '#b388ff',
  '#8c9eff',
  '#82b1ff',
  '#80d8ff',
  '#84ffff',
  '#a7ffeb',
  '#b9f6ca',
  '#ccff90',
  '#f4ff81',
  '#ffff8d',
  '#ffe57f',
  '#ffd180',
  '#ff9e80',
]

const load = divId => {
  let i = 0
  let oTag = null

  oDiv = document.getElementById(divId)

  aA = oDiv.getElementsByTagName('div')

  for (i = 0; i < aA.length; i++) {
    oTag = {}

    oTag.offsetWidth = aA[i].offsetWidth
    oTag.offsetHeight = aA[i].offsetHeight
    aA[i].style.color = colors[Math.floor(Math.random() * colors.length)]

    mcList.push(oTag)
  }

  sineCosine(0, 0, 0)

  positionAll()

  interval = setInterval(() => update(), 30)
}

const destroy = () => {
  clearInterval(interval)
  mcList = []
}

const update = () => {
  const c = 0
  sineCosine(lasta, lastb, c)
  for (let j = 0; j < mcList.length; j++) {
    const rx1 = mcList[j].cx
    const ry1 = mcList[j].cy * ca + mcList[j].cz * -sa
    const rz1 = mcList[j].cy * sa + mcList[j].cz * ca

    const rx2 = rx1 * cb + rz1 * sb
    const ry2 = ry1
    const rz2 = rx1 * -sb + rz1 * cb

    const rx3 = rx2 * cc + ry2 * -sc
    const ry3 = rx2 * sc + ry2 * cc
    const rz3 = rz2

    mcList[j].cx = rx3
    mcList[j].cy = ry3
    mcList[j].cz = rz3

    per = d / (d + rz3)

    mcList[j].x = howElliptical * rx3 * per - howElliptical * 2
    mcList[j].y = ry3 * per
    mcList[j].scale = per
    mcList[j].alpha = per

    mcList[j].alpha = (mcList[j].alpha - 0.6) * (10 / 6)
  }

  doPosition()
  depthSort()
}

const depthSort = () => {
  let i = 0
  const aTmp = []

  for (i = 0; i < aA.length; i++) {
    aTmp.push(aA[i])
  }

  aTmp.sort(function (vItem1, vItem2) {
    if (vItem1.cz > vItem2.cz) {
      return -1
    } else if (vItem1.cz < vItem2.cz) {
      return 1
    } else {
      return 0
    }
  })

  for (i = 0; i < aTmp.length; i++) {
    aTmp[i].style.zIndex = i
  }
}

const positionAll = () => {
  let phi = 0
  let theta = 0
  const max = mcList.length
  let i = 0

  const aTmp = []
  const oFragment = document.createDocumentFragment()

  // Ëæ»úÅÅÐò
  for (i = 0; i < aA.length; i++) {
    aTmp.push(aA[i])
  }

  aTmp.sort(function () {
    return Math.random() < 0.5 ? 1 : -1
  })

  for (i = 0; i < aTmp.length; i++) {
    oFragment.appendChild(aTmp[i])
  }

  oDiv.appendChild(oFragment)

  for (let i = 1; i < max + 1; i++) {
    if (distr) {
      phi = Math.acos(-1 + (2 * i - 1) / max)
      theta = Math.sqrt(max * Math.PI) * phi
    } else {
      phi = Math.random() * Math.PI
      theta = Math.random() * (2 * Math.PI)
    }

    mcList[i - 1].cx = radius * Math.cos(theta) * Math.sin(phi)
    mcList[i - 1].cy = radius * Math.sin(theta) * Math.sin(phi)
    mcList[i - 1].cz = radius * Math.cos(phi)

    aA[i - 1].style.left =
      mcList[i - 1].cx +
      oDiv.offsetWidth / 2 -
      mcList[i - 1].offsetWidth / 2 +
      'px'
    aA[i - 1].style.top =
      mcList[i - 1].cy +
      oDiv.offsetHeight / 2 -
      mcList[i - 1].offsetHeight / 2 +
      'px'
  }
}

const doPosition = () => {
  const l = oDiv.offsetWidth / 2
  const t = oDiv.offsetHeight / 2
  for (let i = 0; i < mcList.length; i++) {
    aA[i].style.left = mcList[i].cx + l - mcList[i].offsetWidth / 2 + 'px'
    aA[i].style.top = mcList[i].cy + t - mcList[i].offsetHeight / 2 + 'px'

    aA[i].style.fontSize = Math.ceil((12 * mcList[i].scale) / 2) + 8 + 'px'

    aA[i].style.filter = 'alpha(opacity=' + 100 * mcList[i].alpha + ')'
    aA[i].style.opacity = mcList[i].alpha
  }
}

const sineCosine = (a, b, c) => {
  sa = Math.sin(a * dtr)
  ca = Math.cos(a * dtr)
  sb = Math.sin(b * dtr)
  cb = Math.cos(b * dtr)
  sc = Math.sin(c * dtr)
  cc = Math.cos(c * dtr)
}

export default {
  load,
  destroy,
}
