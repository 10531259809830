import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CssBaseline,
  ThemeProvider,
  createMuiTheme,
  makeStyles,
  Snackbar,
  IconButton,
  useScrollTrigger,
  Fade,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import Routes from './Routes'
import NavBar from './components/navigation/NavBar'
import Footer from './components/navigation/Footer'
import { dismissRootMessage } from './store/actions/rootActions'
import ScrollToTop from './components/navigation/ScrollToTop'
import FirebaseAnalytics from './FirebaseAnalytics'
import Background from './components/pages/home/Background'

const getTheme = type =>
  createMuiTheme({
    palette: {
      type: type,
      primary: {
        main: '#3caac8',
      },
      secondary: {
        main: '#ff7d00',
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '12px',
        },
      },
      MuiButton: {
        label: {
          opacity: 0.999, // Fix for css animation font-weight flickering
        },
      },
    },
  })

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  offset: {
    minHeight: '88px',
    maxHeight: '88px',
    height: '88px',
    display: 'inline-block',
  },
  content: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
    zIndex: 2,
  },
  nav: {
    top: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    zIndex: 99,
  },
}))

const FadeOnScroll = props => {
  // eslint-disable-next-line react/prop-types
  const { children } = props
  const trigger = useScrollTrigger({ threshold: 0 })
  return (
    <Fade in={!trigger} timeout={500}>
      {children}
    </Fade>
  )
}

let lastMessage = { type: 'error', message: '' }

const Root = props => {
  const messages = useSelector(state => state.root.messages)

  const dispatch = useDispatch()
  const classes = useStyles()

  lastMessage = messages[0] ? messages[0] : lastMessage
  const showMessage = messages.length > 0

  return (
    <Router>
      <FirebaseAnalytics />
      <ScrollToTop />
      <ThemeProvider theme={getTheme('dark')}>
        <CssBaseline />
        <div className={classes.root}>
          <FadeOnScroll>
            <div className={classes.nav}>
              <NavBar />
            </div>
          </FadeOnScroll>
          <div className={classes.offset} />

          <div className={classes.content}>
            <Routes />
          </div>

          <Footer />
          <Background />

          <Snackbar
            open={showMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              severity={lastMessage.type}
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => dispatch(dismissRootMessage())}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {lastMessage.message}
            </Alert>
          </Snackbar>
        </div>
      </ThemeProvider>
    </Router>
  )
}

export default Root
