const images1 = [
  {
    title: 'US Department of Defense',
    url: '/assets/logos/dod-logo.png',
  },
  {
    title: 'US Special Operations Command',
    url: '/assets/logos/socom-logo.png',
  },
  {
    title: 'US Department of Homeland Security',
    url: '/assets/logos/dhs-logo.png',
  },
  {
    title: 'US Federal Emergency Management Agency',
    url: '/assets/logos/fema-logo.png',
  },
]

const images2 = [
  {
    title: 'American Institutes for Research',
    url: '/assets/logos/air-logo.png',
  },
  {
    title: 'Electric Infrastructure Security Council',
    url: '/assets/logos/eis-logo.png',
  },
  {
    title: 'North Atlantic Treaty Organization',
    url: '/assets/logos/nato-logo.png',
  },
]

const technologies = [
  'React',
  'Vue',
  'Angular',
  'Material UI',
  'NodeJS',
  'Express',
  'Python',
  'Flask',
  'MongoDB',
  'MySQL',
  'Firebase',
  'Google Cloud',
  'AWS',
  'PostgreSQL',
  'ESRI',
  'OpenLayers',
  'Leaflet',
  'Docker',
  'Kubernetes',
  'Serverless',
]

export default {
  images1,
  images2,
  technologies,
}
