import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Link,
  Button,
  IconButton,
} from '@material-ui/core'
import MoreIcon from '@material-ui/icons/MoreVert'
import MobileMenu from './MobileMenu'

const useStyles = makeStyles(theme => ({
  appbar: {
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none !important',
  },

  menuButton: {
    marginLeft: theme.spacing(2),
    border: '1px solid rgba(0,0,0,0)',
    '&:hover': {
      border: '1px solid #555',
    },
  },
  secondary: {
    opacity: 0.25,
  },

  desktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  mobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

// eslint-disable-next-line react/display-name
const NavBar = React.forwardRef((props, ref) => {
  const [mobileAnchor, setMobileAnchor] = useState(null)

  const classes = useStyles()

  const gotoAnchor = id => {
    console.log('%c Scrolling to', 'color:lightblue', id)
    document
      .getElementById(id)
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const menu = [
    {
      title: 'Who we are',
      url: '#mission',
    },
    {
      title: 'What we do',
      url: '#services',
    },
    {
      title: 'Technology',
      url: '#technology',
    },
    {
      title: 'Contact us',
      url: '#contact',
    },
  ]

  return (
    <AppBar ref={ref} elevation={0} className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.titleContainer}>
          <Link className={classes.logo} component={RouterLink} to="/">
            <Typography variant="h5" color="textPrimary" noWrap>
              webcrew
            </Typography>
          </Link>
        </div>

        <div className={classes.desktop}>
          {menu.map((item, index) => (
            <Button
              key={index}
              className={classes.menuButton}
              href={item.url}
              onClick={() => gotoAnchor(item.url)}
            >
              {item.title}
            </Button>
          ))}
        </div>

        <div className={classes.mobile}>
          <IconButton
            aria-label="more"
            onClick={e => setMobileAnchor(e.currentTarget)}
          >
            <MoreIcon />
          </IconButton>
        </div>

        <MobileMenu
          anchor={mobileAnchor}
          items={Object.keys(menu).map(k => menu[k])}
          onClick={id => {
            gotoAnchor(id)
            // setMobileAnchor(null)
          }}
          onClose={() => setMobileAnchor(null)}
        />
      </Toolbar>
    </AppBar>
  )
})

export default NavBar
