import React from 'react'
import { makeStyles } from '@material-ui/core'
import ParticleContainer from './ParticleContainer'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    maxWidth: 'unset',
  },
  particles: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundImage: 'url(/assets/background.jpg)',
  },
  coverTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '500px',
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.9) , rgba(0,0,0,0))',
  },
  coverBottom: {
    position: 'absolute',
    top: '500px',
    left: 0,
    width: '100%',
    height: 'calc(100vh - 500px)',
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
}))

const Background = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.particles}>
        <ParticleContainer />
      </div>
      <div className={classes.coverTop} />
      <div className={classes.coverBottom} />
    </div>
  )
}

export default Background
