import {
  ROOT_UPDATE_STATE,
  ROOT_ADD_MESSAGE,
  ROOT_DISMISS_MESSAGE,
} from './types'

export const updateState = obj => dispatch => {
  console.log('updateState', obj)
  dispatch({
    type: ROOT_UPDATE_STATE,
    payload: obj,
  })
}

export const addRootMessage = (type, message) => dispatch => {
  dispatch({
    type: ROOT_ADD_MESSAGE,
    payload: {
      type,
      message,
    },
  })
}

export const dismissRootMessage = () => dispatch => {
  dispatch({
    type: ROOT_DISMISS_MESSAGE,
  })
}
