import {
  ROOT_UPDATE_STATE,
  ROOT_ADD_MESSAGE,
  ROOT_DISMISS_MESSAGE,
} from '../actions/types'

const initialState = {
  messages: [],
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROOT_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case ROOT_ADD_MESSAGE:
      return {
        ...state,
        messages: state.messages.concat([action.payload]),
      }

    case ROOT_DISMISS_MESSAGE:
      return {
        ...state,
        messages: state.messages.slice(1),
      }

    default:
      return state
  }
}

export default rootReducer
