// import { useLocation } from 'react-router-dom'
// import { useEffect } from 'react'
// import firebase from 'firebase/app'

const FirebaseAnalytics = props => {
  // let location = useLocation()
  // useEffect(() => {
  //   const page_path = location.pathname + location.search
  //   firebase.analytics().setCurrentScreen(page_path)
  //   firebase.analytics().logEvent("page_view", { page_path })
  // }, [location])
  return null
}

export default FirebaseAnalytics
